<template>
  <div class="login-container">
    <div class="container-login100 bg-slide">
      <span class="mask bg-gradient-primary opacity-6"></span>
      <div class="wrap-login100">
        <div class="login100-pic" data-tilt>
          <img :src="'/img/theme/card-p2.jpeg'" alt="IMG" />
        </div>



        <el-form :model="forgotForm" status-icon :rules="forgotRules" id="forgotForm" ref="forgotForm">
            <span class="login100-form-title"> Forgot your password? </span>
            
            <el-form-item class="forget-checkbox">
              <el-checkbox v-model="forgotForm.forgot_user">Username</el-checkbox>
              <el-checkbox v-model="forgotForm.forgot_password">Password</el-checkbox>
            </el-form-item>
            
            <el-form-item prop="email" label="Please, enter your email address" class="mb-2">
              <el-input prefix-icon="el-icon-message" type="text" v-model="forgotForm.email" placeholder="Email" autocomplete="off"></el-input>
            </el-form-item>

            <el-form-item>
              <div class="container-login100-form-btn">
                <el-button :loading="loadingForgot" class="login100-form-btn" type="primary" @click="Forgot">Continue</el-button>
              </div>
              <div class="text-center p-t-12">
                <el-button class="link-forget" type="text" @click="$router.push('/login')"> Go back to the sign in page</el-button>
					    </div>
          </el-form-item>
        </el-form>

      </div>
    </div>


  </div>
</template>

<script>
import {forgotPassword} from '@/api/user'
export default {

  data(){
    
    return{
      loadingForgot: false,

      forgotForm: {
        email: '',
        forgot_user: false,
        forgot_password: false
      },
      forgotRules: {
        email: [{
            required: true,
            message: "Please enter your email",
            trigger: "blur",
          },
          {
            type: "email",
            message: "Please enter correct email format",
            trigger: "blur",
          }]
      },
      redirect: undefined,
      otherQuery: {}
    }
  },

  methods:{

    Forgot(){
   
      this.$refs.forgotForm.validate((valid) => {
        if(valid){
          if(!this.forgotForm.forgot_user && !this.forgotForm.forgot_password){
            this.$swal('Message', 'Please select Username or Password', 'error')
            return false
          }
          this.loadingForgot = true
          forgotPassword(this.forgotForm).then(({result, message}) => {
            this.loadingForgot = false
            if(result){
              this.$router.push({ path: '/lost-password' })
            }else{
              this.$swal('Message', message, 'error')
            }
          })
            
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
  created(){
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/login.scss";

.login-container{

  .forget-checkbox{
    &.el-form-item{
      margin-bottom: 0.5rem !important;
    }
    .el-form-item__content{
      line-height: 20px !important;
    }

    label{
      color: #fff;
    }
  }
}
</style>
